<div class="card card-stats mb-0 py-3" [ngStyle]="{ 'background-color': inverted ? color : '#ffffff' }">
  <div class="card-body" [ngClass]="{ 'card-override': clickable }">
    <div class="row">
      <div class="col">
        <h5 class="card-title text-capitalize mb-0" [ngClass]="{ 'text-white': inverted }">
          {{ label }} <span *ngIf="percent != null && percent != '0.00'">/ %</span>
        </h5>
        <span class="h2 font-weight-bolder mb-0" [ngClass]="{ 'text-white': inverted, 'text-primary': clickable }">
          {{ total | number }} <span *ngIf="percent != null && percent != '0.00'"> / {{ percent }}%</span>
        </span>
      </div>
      <div class="col-auto">
        <div class="icon icon-shape rounded-circle shadow" [ngStyle]="{ 'background-color': inverted ? '#ffffff' : color }">
          <i [class]="iconClassNames" [ngStyle]="{ color: inverted ? color : '#ffffff' }"></i>
        </div>
      </div>
    </div>
  </div>
</div>
