import { Component, Input, AfterViewInit, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-overview-report',
  templateUrl: './overview-report.component.html',
  styleUrls: ['./overview-report.component.scss'],
})
export class OverviewReportComponent implements OnInit, OnChanges, AfterViewInit {
  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'OR';

  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();
  @ViewChild(MatPaginator) overviewPaginator: MatPaginator;
  @ViewChild('overviewSort') overviewSort: MatSort = new MatSort();

  columnsToDisplay = [
    'dealer_store_name',
    'assistant_name',
    'customers_messaged',
    'engagements',
    'engagement_percent',
    'appointments',
    'appointment_percent',
    //'triage',
    //'triage_percent',
    'messages_sent',
  ];

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private authService: AuthService, private toaster: ToastrService) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.overviewSort;
    this.dataSource.paginator = this.overviewPaginator;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate &&
        changes.startDate.previousValue &&
        changes.startDate.currentValue &&
        changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate && changes.endDate.previousValue && changes.endDate.currentValue && changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  async getReportDetails() {
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId.split('-')[0],
        this.startDate,
        this.endDate,
        this.reportType,
      );

      // this.reportDetails = [];
      // if (response.tables.report.details !== null) {
      //   this.reportDetails = response.tables.report.details;
      // }

      this.reportDetails = [];
      this.displayTotals = {
        dealer_store_name: 'Totals',
        assistant_name: '',
        customers_messaged: 0,
        appointments: 0,
        engagements: 0,
        triage: 0,
        messages_sent: 0,
        engagement_percent: 0.0,
        appointment_percent: 0.0,
        triage_percent: 0.0,
      };
      if (response.tables.report.details !== null) {
        const result = response.tables.report.details.map(details => {
          let key = 'dealer_store_name';
          const dealer_store_name = details[key];
          key = 'assistant_name';
          const assistant_name = details[key];
          key = 'engagements';
          const engagements = parseInt(details[key], 10);
          key = 'appointments';
          const appointments = parseInt(details[key], 10);
          key = 'customers_messaged';
          const customers_messaged = parseInt(details[key], 10);
          key = 'messages_sent';
          const messages_sent = parseInt(details[key], 10);
          key = 'triage';
          const triage = parseInt(details[key], 10);
          const engagement_percent = (customers_messaged > 0 ? engagements / customers_messaged : 0) * 100;
          const appointment_percent = (engagements > 0 ? appointments / engagements : 0) * 100.0;
          const triage_percent = (engagements > 0 ? triage / engagements : 0) * 100.0;
          return {
            assistant_name,
            dealer_store_name,
            customers_messaged,
            appointments,
            engagements,
            triage,
            messages_sent,
            engagement_percent,
            appointment_percent,
            triage_percent,
          };
        });
        this.reportDetails = result;
        this.reportDetails.forEach((r: any) => {
          this.displayTotals.engagements += r.engagements;
          this.displayTotals.appointments += r.appointments;
          this.displayTotals.customers_messaged += r.customers_messaged;
          this.displayTotals.messages_sent += r.messages_sent;
          this.displayTotals.triage += r.triage;
          this.displayTotals.engagement_percent =
            (this.displayTotals.customers_messaged > 0 ? this.displayTotals.engagements / this.displayTotals.customers_messaged : 0) * 100;
          this.displayTotals.appointment_percent =
            (this.displayTotals.engagements > 0 ? this.displayTotals.appointments / this.displayTotals.engagements : 0) * 100;
          this.displayTotals.triage_percent = (this.displayTotals.engagements > 0 ? this.displayTotals.triage / this.displayTotals.engagements : 0) * 100;
        });
      }

      this.dataSource = new MatTableDataSource(this.reportDetails);
      setTimeout(() => {
        this.dataSource.paginator = this.overviewPaginator;
        this.dataSource.sort = this.overviewSort;
      }, 100);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
