import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { BotModel, CorpModel, HierarchyElementModel } from 'src/app/models';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { BotsService, CorpsService, HierarchyElementsService } from 'src/app/services/firestore';
import { SidebarService } from 'src/app/services/sidebar.service';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { DumbledoreService } from 'src/app/services/dumbledore.service';
import { Permissions } from 'src/app/utils/permissions/permissions';

@Component({
  selector: 'app-bot-knowledge-bank',
  templateUrl: './bot-knowledge-bank.component.html',
  styleUrls: ['./bot-knowledge-bank.component.scss'],
})
export class BotKnowledgeBankComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  isLoading: boolean = true;
  corp: CorpModel;
  hierarchyElement: HierarchyElementModel;
  bot: BotModel;
  botConfig: any;
  API: string =
    'gAAAAABntMrlxSLDGRji-3t0rWasxqupVm1vo61Z3iUC9kqD4IWaWxijhMqR4ELgP0pjXQVXA5kwtKd6gufaoeMt1iStNCKinlmt9F2msNy9hqCAo_KzbPbScubwtMejRUSimuXEderRXU831bbNd-r37Blpto_bu6_r9APWQcLnyiMCnUhFOhkqjgDnidZs8iB7RNpgIiatRPxcF53yRetPKRqvxBWTdT2S7dQFQY09Cc_HhnSh6lvB2i7kD5ooF2hZCEF7tyKg';

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    private corpsService: CorpsService,
    private hierarchyElementsService: HierarchyElementsService,
    private botsService: BotsService,
    private clientEnvironmentService: ClientEnvironmentService,
    private authService: AuthService,
    private toaster: ToastrService,
    private dumbledoreService: DumbledoreService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.activatedRoute.paramMap, this.authService.currentUser, this.clientEnvironmentService.items$]).subscribe(
      ([params, user, envs]) => {
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');
        if (!corpId || !user || !hierarchyElementSystemName || !botCode || !envs) {
          return;
        }

        this.isLoading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
          this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        ]).subscribe(
          async ([corp, hierarchyElement, bot]) => {
            if (!corp || !hierarchyElement || !bot) {
              this.isLoading = false;
              return;
            }

            this.corp = corp;
            this.hierarchyElement = hierarchyElement;
            this.bot = bot;

            this.refreshUI();

            this.botConfig = await this.dumbledoreService.getBotConfigurationByDealershipCode(`${corp.id}-${hierarchyElementSystemName}`);

            const userPermissions = await this.authService.getCurrentUserPermissions();
            const kbPermission = Permissions.CAN_ACCESS_KNOWLEDGE_BANK;
            if (!userPermissions.includes(kbPermission) || !this.botConfig?.knowledgeBase == true) {
              this.toaster.error('You do not have permission to access this page');
              this.router.navigate(['/portal/dashboard']);
            }

            this.isLoading = false;
          },
          error => {
            this.isLoading = false;
            this.toaster.error(error);
          },
        );
      },
    );
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }

  private refreshUI() {
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Knowledge Bank', 'knowledge-bank'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }
}
