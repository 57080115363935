<ng-template [ngIf]="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>

<div class="card card-width" *ngIf="!isLoading">
  <div class="card-header border-0">
    <div class="row mb-2">
      <div class="col-6">
        <h3 class="mb-0">Rep Performance Report</h3>
      </div>
      <div class="col-6 search-box-align" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
        <input type="text" class="report-search-input" placeholder="Search..." (keyup)="onSearch($event)" #input />
      </div>
    </div>
    <div class="row" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
      <mat-table [dataSource]="dataSource" matSort #performanceSort="matSort">
        <ng-container matColumnDef="rep">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Representative </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.rep }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>Totals</mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="customers_assigned">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="align-right"> Customers Assigned </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.customers_assigned.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.customers_assigned.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="engagements">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Engagements </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.engagements.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.engagements.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="engagement_percent">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Engagement % </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.engagement_percent | number : '1.2-2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ (displayTotals ? displayTotals.engagement_percent : 0.0) | number : '1.2-2' }}% </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="appointments">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Appointments </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.appointments.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.appointments.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="appointment_percent">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Appointment % </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.appointment_percent | number : '1.2-2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ (displayTotals ? displayTotals.appointment_percent : 0.0) | number : '1.2-2' }}% </mat-footer-cell>
        </ng-container>

        <!-- Commenting until the team decides if we are going to keep or remove triage
        <ng-container matColumnDef="triage">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Triage </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.triage.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.triage.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="triage_percent">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Triage % </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.triage_percent | number : '1.2-2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ (displayTotals ? displayTotals.triage_percent : 0.0) | number : '1.2-2' }}% </mat-footer-cell>
        </ng-container>
      -->

        <ng-container matColumnDef="messages_sent">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Messages Sent </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.messages_sent.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.messages_sent.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let rows; columns: columnsToDisplay"></mat-row>
        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <div class="row" *ngIf="!reportDetails || reportDetails.length === 0">No Records Found.</div>
  </div>
</div>
