<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center">
  <div class="spinner-border mt-4" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading" class="p-4">
  <impel-cms product="Service AI" [attr.dealer-id]="botConfig.salesforceId" authorization-key="auth" [attr.api-key]="API"></impel-cms>
</div>

<div *ngIf="!isLoading && !botConfig?.salesforceId">
  <div class="d-flex justify-content-center align-items-center">
    <div class="text-center mt-4">
      <p>Knowledge Bank is not available for this bot</p>
    </div>
  </div>
</div>
