import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/common/login/login.component';
import { BotPreviewComponent } from './pages/common/bot-preview/bot-preview.component';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { BotFlowComponent } from './pages/portal/bot/bot-flow/bot-flow.component';
import { BotApiQueriesComponent } from './pages/portal/bot/bot-api-queries/bot-api-queries.component';
import { UnauthorizedComponent } from './pages/portal/unauthorized/unauthorized.component';
import { DashboardComponent } from './pages/portal/landing/dashboard/dashboard.component';
import { CorpDashboardComponent } from './pages/portal/corp/corp-dashboard/corp-dashboard.component';
import { HierarchyElementDashboardComponent } from './pages/portal/hierarchy-element/hierarchy-element-dashboard/hierarchy-element-dashboard.component';
import { BotDeploymentsComponent } from './pages/portal/bot/bot-deployments/bot-deployments.component';
import { BotAnalyticsComponent } from './pages/portal/bot/bot-analytics/bot-analytics.component';
import { CorpsAdminComponent } from './pages/admin/corp-admin/corps-admin/corps-admin.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { AdminGuard } from './services/admin-guard.service';
import { PermissionGuard } from './services/permission-guard.service';
import { PendingChangesGuard } from './services/pending-changes-guard.service';
import { BotAdminComponent } from './pages/admin/bot-admin/bot-admin.component';
import { ProfileComponent } from './pages/portal/profile/profile.component';
import { BotSettingsComponent } from './pages/portal/bot/bot-settings/bot-settings.component';
import { CorpSettingsComponent } from './pages/portal/corp/corp-settings/corp-settings.component';
import { HeirarchyElementSettingsComponent } from './pages/portal/hierarchy-element/hierarchy-element-settings/hierarchy-element-settings.component';
import { NlpModelsComponent } from './pages/portal/nlp/nlp-models/nlp-models.component';
import { NlpDatasetFilesComponent } from './pages/portal/nlp/nlp-dataset-files/nlp-dataset-files.component';
import { NlpDatasetFileComponent } from './pages/portal/nlp/nlp-dataset-file/nlp-dataset-file.component';
import { CorpSettingsAdminComponent } from './pages/admin/corp-admin/corp-settings-admin/corp-settings-admin.component';
import { CorpInputValidationComponent } from './pages/portal/corp/corp-input-validation/corp-input-validation.component';
import { CorpInputValidationsComponent } from './pages/portal/corp/corp-input-validations/corp-input-validations.component';
import { NlpDatasetsComponent } from './pages/portal/nlp/nlp-datasets/nlp-datasets.component';
import { NlpModelEditComponent } from './pages/portal/nlp/nlp-model-edit/nlp-model-edit.component';
import { AdminProfileComponent } from './pages/admin/admin-profile/admin-profile.component';
import { UsersAdminComponent } from './pages/admin/users-admin/users-admin/users-admin.component';
import { Permissions } from './utils/permissions/permissions';
import { RolesAdminComponent } from './pages/admin/roles-admin/roles-admin.component';
import { CorpHierarchiesAdminComponent } from './pages/admin/corp-admin/corp-hierarchies-admin/corp-hierarchies-admin.component';
import { HierarchyElementsAdminComponent } from './pages/admin/hierarchies-admin/hierarchy-elements-admin/hierarchy-elements-admin.component';
import { HierarchyElementBotsAdminComponent } from './pages/admin/hierarchies-admin/hierarchy-element-bots-admin/hierarchy-element-bots-admin.component';
import { CorpFlowTemplatesComponent } from './pages/portal/corp/corp-flow-templates/corp-flow-templates.component';
import { ConversationsMessagesComponent } from './components/conversations/conversations/conversations-messages/conversations-messages.component';
import { BotApiQueryComponent } from './pages/portal/bot/bot-api-query/bot-api-query.component';
import { BotSelectTemplateComponent } from './components/bot/bot-select-template/bot-select-template.component';
import { BotUseTemplateComponent } from './components/bot/bot-use-template/bot-use-template.component';
import { FlowTemplateEditorComponent } from './components/flow-template-editor/flow-template-editor.component';
import { GlobalFlowTemplatesComponent } from './pages/portal/global-flow-templates/global-flow-templates.component';
import { FlowTemplateApiQueriesComponent } from './components/flow-template-api-queries/flow-template-api-queries.component';
import { EditFlowTemplateApiQueryComponent } from './components/edit-flow-template-api-query/edit-flow-template-api-query.component';
import { BotExportComponent } from './pages/portal/bot/bot-export/bot-export.component';
import { UserNotificationsComponent } from './components/notifications/user-notifications/user-notifications.component';
import { HlContainerComponent } from './components/_human-in-the-loop/hl-container/hl-container.component';
import { CreateCampaignComponent } from './components/multi-list/create-campaign/create-campaign.component';
import { BotKnowledgeBankComponent } from './pages/portal/bot/bot-knowledge-bank/bot-knowledge-bank.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'preview/:corp/:hierarchySystemName/:bot', component: BotPreviewComponent },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'corps',
        pathMatch: 'full',
      },
      {
        path: 'corps',
        component: CorpsAdminComponent,
      },
      {
        path: 'roles',
        component: RolesAdminComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_ROLES },
      },
      {
        path: 'users',
        component: UsersAdminComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_USERS },
      },
      {
        path: 'corps/:corp',
        redirectTo: 'corps/:corp/settings',
        pathMatch: 'full',
      },
      {
        path: 'corps/:corp/settings',
        component: CorpSettingsAdminComponent,
      },
      {
        path: 'corps/:corp/hierarchies',
        component: CorpHierarchiesAdminComponent,
      },
      {
        path: 'corps/:corp/hierarchies/:hierarchy',
        component: HierarchyElementsAdminComponent,
      },
      {
        path: 'corps/:corp/hierarchies/:hierarchy/hierarchy-el/:hierarchyElementSystemName',
        component: HierarchyElementsAdminComponent,
      },
      {
        path: 'corps/:corp/hierarchies/:hierarchy/hierarchy-el/:hierarchyElementSystemName/bots',
        component: HierarchyElementBotsAdminComponent,
      },
      {
        path: 'corps/:corp/hierarchies/:hierarchy/hierarchy-el/:hierarchyElementSystemName/bot/:bot',
        component: BotAdminComponent,
      },
      {
        path: 'profile',
        component: AdminProfileComponent,
      },
      {
        path: '**',
        redirectTo: 'corps',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'portal',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'unauthorized',
        component: UnauthorizedComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
      },
      {
        path: 'notifications',
        component: UserNotificationsComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'global-flow-templates',
        component: GlobalFlowTemplatesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.IS_ADMIN },
      },
      {
        path: 'corps/:corp/knowledge-bank',
        component: BotKnowledgeBankComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_ACCESS_KNOWLEDGE_BANK },
      },
      {
        path: 'global-flow-templates/:flowTemplateId/nodes/editor',
        component: FlowTemplateEditorComponent,
        canDeactivate: [PendingChangesGuard],
        canActivate: [PermissionGuard],
        data: { permission: Permissions.IS_ADMIN },
      },
      {
        path: 'global-flow-templates/:flowTemplateId/api-queries',
        component: FlowTemplateApiQueriesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.IS_ADMIN },
      },
      {
        path: 'global-flow-templates/:flowTemplateId/api-queries/:apiQuery',
        component: EditFlowTemplateApiQueryComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.IS_ADMIN },
      },
      // {  TODO: this will be enabled when will fix the analytics on root level
      //   path: 'analytics',
      //   component: AnalyticsComponent,
      //   canActivate: [PermissionGuard]
      // },
      {
        path: 'corps/:corp/dashboard',
        component: CorpDashboardComponent,
        canActivate: [PermissionGuard],
      },
      // {  TODO: this will be enabled when will fix the analytics on corp level
      //   path: 'corps/:corp/analytics',
      //   component: CorpAnalyticsComponent,
      //   canActivate: [PermissionGuard],
      //   data: { permission: Permissions.CAN_READ_CORP_ANALYTICS }
      // },
      {
        path: 'corps/:corp/settings',
        component: CorpSettingsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_CORP_SETTINGS },
      },
      {
        path: 'corps/:corp/human-in-the-loop',
        redirectTo: 'corps/:corp/dashboard',
      },
      {
        path: 'corps/:corp/human-in-the-loop/:conversationId',
        component: HlContainerComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/dashboard',
        component: HierarchyElementDashboardComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/human-in-the-loop',
        redirectTo: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/dashboard',
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/human-in-the-loop/:conversationId',
        component: HlContainerComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/dashboard/select-template',
        component: BotSelectTemplateComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/dashboard/use-template/:templateId',
        component: BotUseTemplateComponent,
        canActivate: [PermissionGuard],
      },
      // {  TODO: this will be enabled when will fix the analytics on hierarchy level
      //   path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/analytics',
      //   component: HierarchyElementAnalyticsComponent,
      //   canActivate: [PermissionGuard],
      //   data: { permission: Permissions.CAN_READ_HIERARCHY_ANALYTICS }
      // },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/settings',
        component: HeirarchyElementSettingsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_HIERARCHY_SETTINGS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/deployments',
        component: BotDeploymentsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_DEPLOYMENTS },
      },
      // {
      //   path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/editor',
      //   component: BotEditorComponent,
      //   canActivate: [PermissionGuard],
      //   data: { permission: Permissions.CAN_READ_BOT_EDITOR }
      // },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/flow',
        component: BotFlowComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [PendingChangesGuard],
        data: { permission: Permissions.CAN_READ_BOT_FLOW },
      },
      {
        path: 'corps/:corp/flow-templates',
        component: CorpFlowTemplatesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_FLOW_TEMPLATES },
      },
      {
        path: 'corps/:corp/flow-templates/:flowTemplateId/nodes/editor',
        component: FlowTemplateEditorComponent,
        canActivate: [PermissionGuard],
        canDeactivate: [PendingChangesGuard],
        data: { permission: Permissions.CAN_READ_FLOW_TEMPLATES },
      },
      {
        path: 'corps/:corp/flow-templates/:flowTemplateId/api-queries',
        component: FlowTemplateApiQueriesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_FLOW_TEMPLATE_API_QUERIES },
      },
      {
        path: 'corps/:corp/flow-templates/:flowTemplateId/api-queries/:apiQuery',
        component: EditFlowTemplateApiQueryComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_WRITE_FLOW_TEMPLATE_API_QUERIES },
      },
      // {
      //   path: 'corps/:corp/flow-template/:flowTemplateId/history',
      //   component: FlowTemplateHistoryComponent,
      //   canActivate: [PermissionGuard],
      //   data: { permission: Permissions.CAN_READ_FLOW_TEMPLATES }
      // },
      {
        path: 'corps/:corp/input-validations',
        component: CorpInputValidationsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_CORP_INPUT_VALIDATIONS },
      },
      {
        path: 'corps/:corp/input-validations/:inputValidation',
        component: CorpInputValidationComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_CORP_INPUT_VALIDATIONS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/api-queries',
        component: BotApiQueriesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_API_QUERIES },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/api-queries/:apiQuery',
        component: BotApiQueryComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_API_QUERIES },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/dashboard',
        component: BotAnalyticsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_ANALYTICS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/conversation-logs',
        component: ConversationsMessagesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_CONVERSATION_LOGS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/conversation-logs/:conversationId',
        component: ConversationsMessagesComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_CONVERSATION_LOGS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/settings',
        component: BotSettingsComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_READ_BOT_SETTINGS },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/human-in-the-loop',
        component: HlContainerComponent,
        canActivate: [PermissionGuard],
        data: { permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL] },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/knowledge-bank',
        component: BotKnowledgeBankComponent,
        canActivate: [PermissionGuard],
        data: { permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL] },
      },

      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/human-in-the-loop',
        component: HlContainerComponent,
      },

      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/dashboard/create-new-campaign',
        component: CreateCampaignComponent,
      },

      {
        path: 'corps/:corp/human-in-the-loop',
        component: HlContainerComponent,
      },

      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/human-in-the-loop',
        component: HlContainerComponent,
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/human-in-the-loop/:conversationId',
        component: HlContainerComponent,
        canActivate: [PermissionGuard],
        data: { permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL] },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/human-in-the-loop/:conversationId/:userId',
        component: HlContainerComponent,
        canActivate: [PermissionGuard],
        data: { permissions: [Permissions.CAN_HANDLE_BOT_SUPPORT, Permissions.CAN_ADMIN_BOT_HIL] },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/export',
        component: BotExportComponent,
        canActivate: [PermissionGuard],
        data: { permission: Permissions.CAN_EXPORT_BOT },
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot',
        redirectTo: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/bots/:bot/dashboard',
        pathMatch: 'prefix',
      },
      {
        path: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName',
        redirectTo: 'corps/:corp/hierarchy-el/:hierarchyElementSystemName/dashboard',
        pathMatch: 'prefix',
      },
      {
        path: 'corps/:corp',
        redirectTo: 'corps/:corp/dashboard',
        pathMatch: 'prefix',
      },
      {
        path: 'nlp/datasets',
        component: NlpDatasetsComponent,
      },
      {
        path: 'nlp/datasets/:dataset',
        component: NlpDatasetFilesComponent,
      },
      {
        path: 'nlp/datasets/:dataset/files/:file',
        component: NlpDatasetFileComponent,
      },
      {
        path: 'nlp/models',
        component: NlpModelsComponent,
      },
      {
        path: 'nlp/models/:model',
        component: NlpModelEditComponent,
      },
      {
        path: '**',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
  { path: '**', redirectTo: 'portal', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
    AngularMyDatePickerModule,
  ],
  exports: [RouterModule, AngularMyDatePickerModule],
})
export class AppRoutingModule {}
