<div class="filter-form-group row">
  <label class="col-md-4 col-form-label form-control-label" for="dateRangePicker"> Date Range </label>
  <div class="col-md-5">
    <input
      type="text"
      class="form-control"
      id="dateRangePicker"
      bsDaterangepicker
      [(ngModel)]="filter.dateRange"
      [bsConfig]="{
        isAnimated: true,
        containerClass: 'theme-dark-blue',
        maxDate: dateOfToday,
        showWeekNumbers: false
      }"
      [disabled]="filter.anyDate"
      name="bsDaterangepicker"
    />
  </div>
  <div class="col-md-3">
    <div class="custom-control custom-checkbox m-2 float-right">
      <input
        class="custom-control-input"
        id="dateAnyDateCheckbox"
        type="checkbox"
        [checked]="filter.anyDate == true"
        (change)="filter.anyDate = !filter.anyDate"
      /><label class="custom-control-label" for="dateAnyDateCheckbox"> Any date </label>
    </div>
  </div>
</div>
<div class="filter-form-group row">
  <label class="col-md-4 col-form-label form-control-label" for="conversationIdFilter"> By Conversation Id </label>
  <div class="col-md-8">
    <div class="input-group input-group-alternative input-group-merge">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fas fa-search"> </i>
        </span>
      </div>
      <input class="form-control" id="conversationIdFilter" name="conversationId" [(ngModel)]="filter.conversationId" type="text" />
    </div>
  </div>
</div>
<div class="filter-form-group row">
  <label class="col-md-4 col-form-label form-control-label" for="userIdFilter"> By User Id </label>
  <div class="col-md-8">
    <div class="input-group input-group-alternative input-group-merge">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fas fa-search"> </i>
        </span>
      </div>
      <input class="form-control" id="userIdFilter" name="userId" [(ngModel)]="filter.userId" type="text" />
    </div>
  </div>
</div>
<div class="filter-form-group row">
  <label class="col-md-4 col-form-label form-control-label" for="containingTextFilter"> Containing Text </label>
  <div class="col-md-8">
    <div class="input-group input-group-alternative input-group-merge">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fas fa-search"> </i>
        </span>
      </div>
      <input class="form-control" id="containingTextFilter" name="containingText" [(ngModel)]="filter.containingText" type="text" />
    </div>
  </div>
</div>

<div class="filter-form-group border-top">
  <div class="">
    <label class="col-form-label form-control-label px-0 mr-2"> Sort by: </label>
    <select class="form-control-sm" name="sortBy" [(ngModel)]="filter.sortByTimeOrder" (ngModelChange)="onSortOrderChange()">
      <option value="asc">Oldest</option>
      <option value="desc">Newest</option>
    </select>
  </div>
</div>
