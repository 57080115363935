<ng-template [ngIf]="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>

<div class="card card-width" *ngIf="!isLoading">
  <div class="card-header border-0">
    <div class="row mb-2">
      <div class="col-6">
        <h3 class="mb-0">Repair Order Report</h3>
      </div>
      <div class="col-6 search-box-align" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
        <input type="text" class="report-search-input" placeholder="Search..." (keyup)="onSearch($event)" #input />
      </div>
    </div>
    <div class="row" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
      <mat-table [dataSource]="dataSource" matSort #repairOrderSort="matSort">
        <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
          <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
          <mat-cell *cdkCellDef="let row">{{ column.cell(row).toLocaleString('en-US') }}</mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ column.total.toLocaleString('en-US') }} </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: columnsToDisplay"></mat-row>
        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <div class="row" *ngIf="!reportDetails || reportDetails.length === 0">No Records Found.</div>
  </div>
</div>
