<div class="container-fluid">
  <ng-template [ngIf]="loading && !showReport">
    <div class="d-flex mt-3 justify-content-center">
      <app-loading></app-loading>
    </div>
  </ng-template>
  <ng-template [ngIf]="!loading && botExists">
    <div class="nav-wrapper">
      <ul class="nav nav-pills nav-pills--group flex-column flex-sm-row">
        <li *ngFor="let tab of getTabs(); let isLast = last" class="nav-item">
          <a
            *ngIf="tab.visible"
            role="button"
            class="cursor-pointer nav-link mb-sm-3 mb-md-0 text-primary"
            [ngClass]="{
              active: tab.slug === currentTab,
              disabled: !canViewDataExport() && tab.slug === 'data-export',
              'last-link': isLast
            }"
            (click)="setTab(tab.slug)"
          >
            {{ tab.label }}
          </a>
        </li>
        <li>
          <div style="display: flex; align-items: center" class="ml-4">
            <div style="display: inline; vertical-align: middle">Date&nbsp;Range:&nbsp;</div>
            <div style="display: inline; vertical-align: middle">
              <input type="text" placeholder="Enter Starting Date" class="form-control" bsDatepicker [(bsValue)]="startDate" />
            </div>
            <div style="vertical-align: middle; display: inline">&nbsp;through&nbsp;</div>
            <div style="display: inline; vertical-align: middle">
              <input type="text" placeholder="Enter Starting Date" class="form-control" bsDatepicker [(bsValue)]="endDate" />
            </div>
            <div *ngIf="newEndDate < newStartDate">&nbsp; * Starting date must be less than or equal to the ending date *.</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="column">
      <ng-container *ngIf="currentTab === 'overview'">
        <app-overview-charts
          (reportTypeEvent)="getReportType($event)"
          [botId]="botId"
          [startDate]="startDate"
          [endDate]="endDate"
          [showReport]="showReport"
        ></app-overview-charts>
        <app-user-recent-conversations
          [awsRegion]="awsRegion"
          [botId]="botId"
          [startDate]="startDate"
          [endDate]="endDate"
          [reportType]="reportType"
          [showReport]="showReport"
        ></app-user-recent-conversations>
      </ng-container>
      <ng-container *ngIf="currentTab === 'new-reports'">
        <app-reports [botId]="botId" [startDate]="startDate" [endDate]="endDate"> </app-reports>
      </ng-container>

      <ng-container *ngIf="currentTab === 'reports'">
        <app-analytics-cards-topline-stats [awsRegion]="awsRegion" [botId]="botId"></app-analytics-cards-topline-stats>
        <div class="row">
          <div class="col-6">
            <app-chart-traffic [awsRegion]="awsRegion" [botId]="botId"></app-chart-traffic>
          </div>
          <div class="col-6">
            <app-chart-unique-users [awsRegion]="awsRegion" [botId]="botId"></app-chart-unique-users>
          </div>
          <div class="col-6">
            <app-chart-top-intents [awsRegion]="awsRegion" [botId]="botId"></app-chart-top-intents>
          </div>
          <div class="col-6">
            <app-chart-users-by-channel [awsRegion]="awsRegion" [botId]="botId"></app-chart-users-by-channel>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTab === 'kpis'">
        <app-kpis-table [awsRegion]="awsRegion" [botId]="botId"></app-kpis-table>
      </ng-container>
      <ng-container *ngIf="currentTab === 'data-export'">
        <app-data-export [awsRegion]="awsRegion" [botId]="botId"></app-data-export>
      </ng-container>
      <ng-container *ngIf="currentTab === 'recall-campaigns'">
        <app-bot-upload-campaigns [botId]="botId" [userEmail]="currentUserEmail" [userName]="currentUserName"></app-bot-upload-campaigns>
      </ng-container>
    </div>
  </ng-template>
</div>
