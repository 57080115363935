import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/services/header.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { CorpModel } from 'src/app/models/corp';
import { ApiQueryModel } from 'src/app/models/api-query';
import { getSidebarItems, getBreadcrumbItems } from '../utils';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { BotModel } from 'src/app/models/bot';
import { HierarchyElementModel } from 'src/app/models/hierarchy-element';
import { HierarchyElementsService, ApiQueriesService, BotsService, CorpsService } from 'src/app/services/firestore';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';

@Component({
  selector: 'app-bot-api-queries',
  templateUrl: './bot-api-queries.component.html',
  styleUrls: ['./bot-api-queries.component.scss'],
})
export class BotApiQueriesComponent implements OnInit, OnDestroy {
  private paramMapSubscription: Subscription;
  private crtDataSubscription: Subscription;
  private apiQueriesSubscription: Subscription;
  corp: CorpModel;
  bot: BotModel;
  baseUrl: string;
  hierarchyElement: HierarchyElementModel;
  apiQueries: ApiQueryModel[];
  loading: boolean;
  permissionToWriteApiQueries = Permissions.CAN_WRITE_BOT_API_QUERIES;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private route: ActivatedRoute,
    private botsService: BotsService,
    private corpsService: CorpsService,
    private apiQueriesService: ApiQueriesService,
    private headerService: HeaderService,
    private authService: AuthService,
    private hierarchyElementsService: HierarchyElementsService,
    private toaster: ToastrService,
    private clientEnvironmentService: ClientEnvironmentService,
  ) {}

  ngOnInit() {
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser, this.clientEnvironmentService.items$]).subscribe(
      ([params, user, envs]) => {
        console.log('envs', envs);
        const corpId = params.get('corp');
        const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
        const botCode = params.get('bot');
        if (!corpId || !user || !hierarchyElementSystemName || !botCode || !envs) {
          return;
        }

        this.loading = true;
        this.crtDataSubscription = combineLatest([
          this.corpsService.getCorpById(corpId),
          this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
          this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs),
        ]).subscribe(
          ([corp, hierarchyElement, bot]) => {
            if (!corp || !hierarchyElement || !bot) {
              this.loading = false;
              return;
            }

            this.corp = corp;
            this.hierarchyElement = hierarchyElement;
            this.bot = bot;

            this.apiQueriesSubscription = this.apiQueriesService.getApiQueriesByBotId(this.bot.id).subscribe(apiQueries => {
              if (!apiQueries) {
                return;
              }

              this.loading = false;
              this.apiQueries = apiQueries;
              this.baseUrl = `/portal/corps/${corpId}/hierarchy-el/${hierarchyElement.systemNameForUrl}/bots/${botCode}`;
              this.refreshUI();
            });
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.toaster.error(error);
          },
        );
      },
    );
  }

  async handleApiQueryDelete(apiQueryId: string) {
    try {
      await this.apiQueriesService.removeApiQuery(apiQueryId);
      this.toaster.success('API Query Deleted Successfully');
    } catch (error) {
      this.toaster.error(error);
    }
  }

  private refreshUI() {
    this.headerService.setPageTitle(`${this.bot.label} API Queries`);
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'API Queries', 'api_queries'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.apiQueriesSubscription) {
      this.apiQueriesSubscription.unsubscribe();
    }
  }
}
