import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  //QueryList,
} from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-intent-report',
  templateUrl: './intent-report.component.html',
  styleUrls: ['./intent-report.component.scss'],
})
export class IntentReportComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('intentSort') intentSort: MatSort = new MatSort();
  @ViewChild(MatPaginator) intentPaginator: MatPaginator;
  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();

  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'URR';
  columnsToDisplay = ['Intent', 'Count', 'Pct'];

  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private authService: AuthService, private toaster: ToastrService) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.intentPaginator;
    this.dataSource.sort = this.intentSort;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate &&
        changes.startDate.previousValue &&
        changes.startDate.currentValue &&
        changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate && changes.endDate.previousValue && changes.endDate.currentValue && changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  async getReportDetails() {
    try {
      if (!this.reportDetails || this.reportDetails.length === 0) {
        const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
          this.botId,
          this.startDate,
          this.endDate,
          this.reportType,
        );
        this.reportDetails = [];
        if (response.tables.report.details !== null) {
          let total = 0;
          let key = 'intent_count';
          response.tables.report.details.forEach((detail, index) => (total += index !== response.tables.report.details.length - 1 ? detail[key] : 0));
          const result = await Promise.all(
            response.tables.report.details.map(async details => {
              key = 'intent_count';
              const intent_count = parseInt(details[key], 10);
              key = 'last_intent_label';
              return {
                Intent: details[key],
                Count: intent_count,
                Pct: ((intent_count > 0 ? intent_count / total : 0) * 100.0).toFixed(2),
              };
            }),
          );
          this.displayTotals = result.length > 0 ? result.slice(-1) : [];
          if (this.displayTotals?.[0]?.Intent?.trim() === '* Total *') {
            this.displayTotals[0].Intent = 'Total';
          }
          result.splice(result.length - 1);
          this.reportDetails = result;
        }
      }
      this.dataSource = new MatTableDataSource(this.reportDetails);
      setTimeout(() => {
        this.dataSource.paginator = this.intentPaginator;
        this.dataSource.sort = this.intentSort;
      }, 100);
    } catch (error) {
      console.log(error);
      this.toaster.error(error);
    }
  }
}
