import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { ApiGatewayAnalyticsService } from 'src/app/services/api-gateway-analytics/api-gateway-analytics.service';
import { AnalyticsDashboardData } from 'src/app/services/api-gateway-analytics/types';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-engagement-report',
  templateUrl: './engagement-report.component.html',
  styleUrls: ['./engagement-report.component.scss'],
})
export class EngagementReportComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('engagementSort') engagementSort: MatSort = new MatSort();
  @ViewChild(MatPaginator) engagementPaginator: MatPaginator;
  reportDetails;
  displayTotals;
  dataSource;
  isLoading: boolean;
  reportType = 'CR';
  max_columns = 0;

  @Input() awsRegion: AwsRegionEnum;
  @Input() botId: string;
  @Input() startDate: Date = new Date(2000, 0, 1);
  @Input() endDate: Date = new Date();

  columnsToDisplay = ['last_process_type', 'customers_messaged', 'engagements', 'engagement_percent'];
  constructor(private apiGatewayAnalyticsService: ApiGatewayAnalyticsService, private authService: AuthService, private toaster: ToastrService) {}

  async ngOnInit() {
    const user = await this.authService.currentUser;
    this.isLoading = true;
    await this.getReportDetails();
    this.isLoading = false;
  }

  async ngAfterViewInit() {
    this.dataSource.paginator = this.engagementPaginator;
    this.dataSource.sort = this.engagementSort;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.startDate &&
        changes.startDate.previousValue &&
        changes.startDate.currentValue &&
        changes.startDate.currentValue !== changes.startDate.previousValue) ||
      (changes.endDate && changes.endDate.previousValue && changes.endDate.currentValue && changes.endDate.currentValue !== changes.endDate.previousValue)
    ) {
      this.isLoading = true;
      this.reportDetails = [];
      await this.getReportDetails();
      this.isLoading = false;
    }
  }

  onSearch(event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  async getReportDetails() {
    try {
      const response: AnalyticsDashboardData = await this.apiGatewayAnalyticsService.getAnalyticsDashboardData(
        this.botId,
        this.startDate,
        this.endDate,
        this.reportType,
      );

      this.displayTotals = {
        last_process_type: 'Totals',
        customers_messaged: 0,
        engagements: 0,
        engagement_percent: 0.0,
        avg_time: 0,
        appointments: 0,
        appointment_percent: 0.0,
        campaign_ends: 5,
      };
      this.reportDetails = [];
      let total_time = 0;
      if (response.tables.report.details !== null) {
        const details = response.tables.report.details;
        let key = 'campaign_ends';
        for (const detail of details) {
          if (parseInt(detail[key], 10) > this.max_columns) {
            this.max_columns = parseInt(detail[key], 10);
          }
        }

        if (this.columnsToDisplay.length <= 6) {
          for (let i = 1; i <= this.max_columns; i++) {
            this.columnsToDisplay.push('E' + i.toString());
            this.displayTotals['E' + i.toString()] = 0;
          }
          this.displayTotals['avg_time'] = 0;
          this.columnsToDisplay.push('avg_time');
        }

        const result = await Promise.all(
          response.tables.report.details.map(async detail => {
            let engagements = 0;
            key = 'last_process_type';
            const last_process_type: string = detail[key];
            key = 'customer_messages';
            const customers_messaged = parseInt(detail[key] || '0', 10);
            key = 'appointments';
            const appointments = parseInt(detail[key] || '0', 10);
            key = 'campaign_ends';
            const campaign_ends = parseInt(detail[key] || '5', 10);
            key = 'avg_time_to_engagement';
            let time = parseInt(detail[key] || '0', 10);
            const columns: number[] = [];
            for (let i = 1; i <= this.max_columns; i++) {
              const field = 'e' + i.toString();
              const value = detail[field] ? parseInt(detail[field], 10) : 0;
              engagements += value;
              columns.push(value);
            }

            total_time += time;
            const day = Math.floor(time / 24);
            const hour = Math.floor(time % 24);
            const avg_time = day.toString() + 'D ' + hour.toString() + 'H';
            const engagement_percent = (customers_messaged > 0 ? engagements / customers_messaged : 0) * 100.0;
            const appointment_percent = (engagements > 0 ? appointments / engagements : 0) * 100.0;
            let return_value: object = {
              last_process_type,
              customers_messaged,
              engagements,
              engagement_percent,
              avg_time,
              appointments,
              appointment_percent,
              campaign_ends,
            };

            columns.forEach((column, index) => {
              return_value = { ...return_value, ['E' + (index + 1).toString()]: column };
            });

            return return_value;
          }),
        );

        result.forEach((r: any) => {
          this.displayTotals.customers_messaged += r.customers_messaged;
          this.displayTotals.engagements += r.engagements;
          this.displayTotals.appointments += r.appointments;
          this.displayTotals.campaign_ends = this.displayTotals.campaign_ends < r.campaign_ends ? r.campaign_ends : this.displayTotals.campaign_ends;
          const time = result.length === 0 ? 0 : total_time / result.length;

          const day = Math.floor(time / 24);
          const hour = Math.floor(time % 24);
          const avg_time = day.toString() + 'D ' + hour.toString() + 'H';
          this.displayTotals.avg_time = avg_time;
          this.displayTotals.engagement_percent =
            (this.displayTotals.customers_messaged > 0 ? this.displayTotals.engagements / this.displayTotals.customers_messaged : 0) * 100.0;
          this.displayTotals.appointment_percent =
            (this.displayTotals.engagements > 0 ? this.displayTotals.appointments / this.displayTotals.engagements : 0) * 100.0;

          for (let i = 1; i <= this.max_columns; i++) {
            const field = 'E' + i.toString();
            const value = parseInt(r[field], 10);

            if (this.displayTotals[field] === undefined) {
              this.displayTotals[field] = 0;
            }
            this.displayTotals[field] += value;
          }
        });

        // this.displayTotals = result.length > 0 ? result.slice(-1) : [];
        this.reportDetails = result;
        // this.reportDetails.splice(result.length - 1);
        this.dataSource = new MatTableDataSource(this.reportDetails);
        setTimeout(() => {
          this.dataSource.paginator = this.engagementPaginator;
          this.dataSource.sort = this.engagementSort;
        }, 100);
      }
    } catch (error) {
      console.log('ERROR FOUND');
      console.log(error);
      this.toaster.error(error);
    }
  }
}
