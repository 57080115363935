import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AnalyticsDashboardCards } from 'src/app/services/api-gateway-analytics/types';
import { EnhancedChart } from './enhanced-chart';
import { colors } from './enhanced-chart/settings';

@Component({
  selector: 'app-analytics-chart',
  templateUrl: './analytics-chart.component.html',
  styleUrls: ['./analytics-chart.component.scss'],
})
export class AnalyticsChartComponent implements OnInit {
  @Input() dataset: Record<AnalyticsDashboardCards, number>;
  @Input() startDate = new Date(2000, 0, 1);
  @Input() endDate = new Date();
  @Input() reportType: string = 'T';
  @Output() reportTypeEvent = new EventEmitter<string>();

  public reportChanged(report: string) {
    const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
    if (reportDays <= 185) {
      this.reportType = report;
      this.reportTypeEvent.emit(report);
    }
  }

  public chart: Chart;
  public data: {};

  constructor() {}

  ngOnInit() {
    this.data = {
      labels: ['Messages Sent', 'Customers Messaged', 'Engagement'],
      datasets: [
        {
          labels: ['Messages Sent', 'Total Outreach', 'Engagement'],
          backgroundColor: [colors.barcolor1, colors.barcolor2, colors.barcolor3],
          data: [this.dataset.messagesSent, this.dataset.totalOutreach, this.dataset.engagements],
        },
      ],
    };

    this.chart = new EnhancedChart('analytics-chart', {
      options: {
        onClick: (e, element) => {
          if (!element) return;
          if (element.length === 0) return;
          const bar = element[0];
          switch (bar['_index']) {
            case 0:
              return;
            case 1:
              this.reportChanged('C');
              break;
            case 2:
              this.reportChanged('E');
              break;

            case 3:
              this.reportChanged('T');
              break;
          }
        },
        hover: {
          onHover: (e, element) => {
            if (e && e.target) e.target['style'].cursor = 'default';
            if (element && element.length > 0 && element[0]['_index'] > 0 && e && e.target) e.target['style'].cursor = 'pointer';
          },
        },
      },
      type: 'bar',
      data: this.data,
    });
  }
}
